import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import ContactTwo from "@/components/contact-two";
import ServiceDetails from "@/components/service-details";
import Ferrara05 from "@/images/ferrara/ferrara_05.jpg";


const ServiceDetailsPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Pro Bono">
        <HeaderOne />
        <PageHeader title="Pro Bono" image={Ferrara05}  name="Pro Bono" />
        <ServiceDetails />
        <ContactTwo />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPage;
